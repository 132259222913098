<template>
  <div>
    <b-card title="Create Event">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row></b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="Temporada" rules="required">
                <b-form-group label="Season" label-for="input-default">
                  <b-form-select v-model="temporadaAforoSelected" :options="teporadaAforoOptions"
                    @change="configureAforos()" :disabled="disable" :state="errors.length > 0 ? false : null" autofocus />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group label="Id Event" label-for="input-default">
                <b-form-input id="input-default" v-model="body.ID_Event" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="Event Name" rules="required">
                <b-form-group label="Event Name" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.event_name" :plaintext="togglePlaintext"
                    :state="errors.length > 0 ? false : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="Event Date" rules="required">
              <b-form-group label="Event Date" label-for="input-default">
                <flat-pickr :disabled="disable" v-model="body.Date" :state="errors.length > 0 ? false : null" class="form-control" :config="{
                  enableTime: true, defaultDate: body.Date,
                  time_24hr: true,
                }" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group label="Event Active" label-for="input-default">
                <b-form-select v-model="body.mxs_event_Active" :options="activeOptions" :disabled="disable" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group label="Id Competition" label-for="input-default">
                <b-form-input id="input-default" v-model="body.ID_Competition" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <b-form-group label="Competition Name" label-for="input-default">
                <b-form-input id="input-default" v-model="body.Competition_name" :plaintext="togglePlaintext" />
              </b-form-group>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="Accred. Start" rules="required">
              <b-form-group label="Accred. Start" label-for="input-default">
                <flat-pickr :disabled="disable" v-model="body.timestamp_accreditation_start" :state="errors.length > 0 ? false : null" class="form-control" :config="{
                  enableTime: true, defaultDate: body.timestamp_accreditation_start,
                  time_24hr: true,
                }" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col>
              <validation-provider #default="{ errors }" name="Accred. End" rules="required">
              <b-form-group label="Accred. End" label-for="input-default">
                <flat-pickr :disabled="disable" v-model="body.timestamp_accreditation_end" :state="errors.length > 0 ? false : null" class="form-control" :config="{
                  enableTime: true, defaultDate: body.timestamp_accreditation_end,
                  time_24hr: true,
                }" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col></b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-row></b-row>
      <b-row>
        <b-col></b-col>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm" :hidden="hiddenComponent">
          <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
          Create Event
        </b-button>
        <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/events">
          <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
          Go Back
        </b-button>
        <b-col></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import { decryptString } from '@/libs/crypto'

export default {
  components: {
    BCard, BCardText, BTabs, BTab, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol, BFormSelect, ValidationProvider, ValidationObserver,

    flatPickr,
  },
  computed: {
    teporadaAforoOptions() {
      this.temporadesAforos = this.temporadesAforos.map((t) => ({ text: t, value: t }))
      return this.temporadesAforos
    },
    configureAforos() {
      const temporadaAforo = this.temporadaAforoSelected.split(" - ");
      this.body.ID_Aforo = temporadaAforo[0];
      this.body.Temporada = temporadaAforo[1];
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.message();
        }
      })
    },
    async message() {
      const error = await this.onSubmit();
      if (error[0]) {
        const variant = "danger";
        this.$bvToast.toast("" + error[1], {
          title: "Failed",
          variant,
          solid: true,
        });
      } else if (!error[0]) {
        this.togglePlaintext = true;
        this.disable = true;
        this.hiddenComponent = true;
        const variant = "success";
        this.$bvToast.toast("Event created correctly", {
          title: `Create`,
          variant,
          solid: true,
        });
      }
    },
  },
  data() {
    return {
      required,
      disable: false,
      hiddenComponent: false,
      togglePlaintext: false,
      activeOptions: [
        { value: "true", text: 'Active' },
        { value: "false", text: 'Inactive' },
      ],
      temporadesAforos: [],
      temporadaAforoSelected: "",
    };
  },
  async created() {

    await axios
      .get("/aforo?Active=1")
      .then((results) => {
        results.data.forEach(element => {
          this.temporadesAforos.push(element.ID_Aforo + " - " + element.TEMPORADA + " - " + element.Aforo_name)
        });
      })
      .catch((error) => {
        const variant = "danger";
        this.$bvToast.toast("Failed to connect", {
          title: `` + error,
          variant,
          solid: true,
        });
      })

  },
  mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
  setup() {
    const blankEventData = {
      Temporada: "",
      ID_Aforo: "",
      ID_Event: "",
      event_name: "",
      ID_Competition: "",
      Competition_name: "",
      Date: "",
      mxs_event_Active: "true",
      timestamp_accreditation_start: "",
      timestamp_accreditation_end: "",
      IdUser: decryptString(localStorage.getItem('idUser')),
    };

    const body = ref(JSON.parse(JSON.stringify(blankEventData)));

    const onSubmit = async () => {
      const onError = [false, ""];
      await axios
        .post("/event", body.value)
        .then((result) => {
          onError[0] = false;
        })
        .catch((error) => {
          onError[0] = true;
          onError[1] = error;
        });
      return onError;
    };


    return {
      body,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.row {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
</style>
